













































































































































import { SelectedFilters } from "@/interfaces/shared/selected_filters";
import { AssessmentVersions } from "@/store/modules/recruiter/interfaces";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "FiltersBadge",
  data() {
    return {
      filter_overlay: false,
      filter_icon: require("@/assets/icons/bold/filter.svg"),
      selectedFilter: [AssessmentVersions.VERSION_3],
      scores: {
        english: [0, 100],
        average: [0, 100],
        annotation: [0, 100]
      }
    };
  },
  computed: {},
  props: {
    average_score: {
      type: Array,
      default: () => [0, 100]
    },
    english_score: {
      type: Array,
      default: () => [0, 100]
    },
    annotation_score: {
      type: Array,
      default: () => [0, 100]
    },
    filters: {
      type: Array as PropType<SelectedFilters[]>
    },
    assessment_versions: {
      type: Array as PropType<AssessmentVersions[]>,
      default: AssessmentVersions.VERSION_3
    }
  },
  watch: {
    assessment_versions: {
      immediate: true,
      handler(newVal) {
        this.selectedFilter = newVal;
      }
    },
    average_score: {
      immediate: true,
      handler(newVal) {
        this.scores.average = newVal;
      }
    },
    english_score: {
      immediate: true,
      handler(newVal) {
        this.scores.english = newVal;
      }
    },
    annotation_score: {
      immediate: true,
      handler(newVal) {
        this.scores.annotation = newVal;
      }
    }
  },
  methods: {
    toggle_filter_overlay() {
      this.filter_overlay = !this.filter_overlay;
    },
    filter_change() {
      console.log(this.selectedFilter, "===============this.selectedFilter");
      const payload = {
        average_score: this.scores.average,
        english_score: this.scores.english,
        annotation_score: this.scores.annotation,
        assessment_versions: this.selectedFilter
      };
      this.$emit("filter_change", payload);
      this.filter_overlay = false;
    }
  }
});
