
























































































































import Vue from "vue";
import EnglishSelectedDatePicker from "@/components/recruiter/app_assessment/english/EngishSelectedDatePicker.vue";
import EnglishAutoCompleteUserSearch from "@/components/recruiter/app_assessment/english/EnglishAutoCompleteUserSearch.vue";
import AnnotationAutoCompleteUserSearch from "@/components/recruiter/app_assessment/Annotation/AnnotationAutoCompleteUserSearch.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { mapGetters } from "vuex";
import {
  ACTIVE_ASSESSMENT,
  APP_ANNOTATION_ASSESSMENT_LISTING,
  APP_ENG_ASSESSMENT_LISTING
} from "@/store/modules/recruiter/constants";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
import AnnotationSelectedDatePicker from "./Annotation/AnnotationSelectedDatePicker.vue";
import {
  AppAnnotationAssessmentListing,
  AppEngAssessmentListing,
  AssessmentVersions
} from "@/store/modules/recruiter/interfaces";
// import FiltersBadge from "@/components/recruiter/FiltersBadge.vue";
import { TranslateResult } from "vue-i18n";
import SelectOptions from "@/components/recruiter/SelectOptions.vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { SelectedFilters } from "@/interfaces/shared/selected_filters";
import AutoCompleteUserAndEidSearch from "@/components/shared/AutoCompleteUserAndEidSearch.vue";
import AssessmentsFilter from "@/components/recruiter/AssessmentsFilter.vue";

export default Vue.extend({
  name: "AppAssessmentListingFilterHeader",
  components: {
    EnglishAutoCompleteUserSearch,
    AnnotationAutoCompleteUserSearch,
    EnglishSelectedDatePicker,
    AnnotationSelectedDatePicker,
    // FiltersBadge,
    SelectOptions,
    AutoCompleteUserAndEidSearch,
    AssessmentsFilter
  },
  data() {
    return {
      dates: [] as string[],
      english_placeholder: this.$t("auth.name") as TranslateResult,
      annotation_placeholder: this.$t("auth.name") as TranslateResult,
      pass_fail_filters: [
        { label: this.$t("assessments.both"), value: "both" },
        { label: this.$t("assessments.pass"), value: "pass" },
        { label: this.$t("assessments.fail"), value: "fail" }
      ] as SelectedFilters[],
      completed_failed_filter: [
        { label: this.$t("assessments.filters.all"), value: "all" },
        {
          label: this.$t("assessments.filters.completed"),
          value: AppAssessments.AppAssessmentStatus.COMPLETED
        },
        {
          label: this.$t("assessments.filters.failed"),
          value: AppAssessments.AppAssessmentStatus.FAILED
        },
        {
          label: this.$t("assessments.filters.cancelled"),
          value: AppAssessments.AppAssessmentStatus.CANCELLED
        },
        {
          label: this.$t("assessments.filters.in-progress"),
          value: AppAssessments.AppAssessmentStatus.IN_PROGRESS
        }
      ] as SelectedFilters[],
      fetch_filters: [
        { label: this.$t("assessments.filters.all"), value: "all" },
        { label: this.$t("auth.name"), value: this.$t("auth.name").toString() },
        { label: this.$t("assessments.fail"), value: "eid-number" }
      ],
      version_filters: [
        { label: "Version 1", value: AssessmentVersions.VERSION_1 },
        { label: "Version 2", value: AssessmentVersions.VERSION_2 },
        { label: "Version 3", value: AssessmentVersions.VERSION_3 }
      ] as SelectedFilters[]
    };
  },
  computed: {
    AppAssessmentNames() {
      return AppAssessmentNames;
    },
    ...mapGetters("recruiter", {
      app_english_assessments: APP_ENG_ASSESSMENT_LISTING,
      app_annotation_assessments: APP_ANNOTATION_ASSESSMENT_LISTING,
      get_active_assessment: ACTIVE_ASSESSMENT
    }),
    CurrentEngAssessment(): AppEngAssessmentListing {
      return this.app_english_assessments(this.version);
    },
    CurrentAnnotationAssessment(): AppAnnotationAssessmentListing {
      return this.app_annotation_assessments(this.version);
    },
    EnglishAssessmentFilter(): string {
      return this.CurrentEngAssessment?.score_filter ?? "both";
    },
    AnnotationAssessmentFilter(): string {
      return this.CurrentAnnotationAssessment?.score_filter ?? "both";
    },
    EnglishAssessmentStatusFilter(): string {
      return this.CurrentEngAssessment?.status_filter ?? "all";
    },
    AnnotationAssessmentStatusFilter(): string {
      return this.CurrentAnnotationAssessment?.status_filter ?? "all";
    }
  },
  mounted() {
    console.log(
      this.get_active_assessment.assessment_type,
      "this.get_active_assessment.assessment_type"
    );
  },
  props: {
    version: {
      type: String
    },
    is_overall_result: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    searched_user(value: SearchUserObject[]) {
      const is_english_assessment =
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v1 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v2 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v3;
      if (!value.length) {
        if (is_english_assessment)
          this.CurrentEngAssessment.selected_candidates = [];
        else this.CurrentAnnotationAssessment.selected_candidates = [];
        return;
      }
      const dates = is_english_assessment
        ? this.CurrentEngAssessment.selected_dates.date_filter
        : this.CurrentAnnotationAssessment.selected_dates.date_filter;
      this.extract_assessments_with_filters(value, dates);
    },
    //  Selected Searched Dates
    selected_dates(dates: string[]) {
      const is_english_assessment =
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v1 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v2 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v3;
      if (!dates.length) {
        if (is_english_assessment)
          this.CurrentEngAssessment.selected_dates = {
            date_picker_menu: false,
            date_range_value: "",
            date_filter: []
          };
        else
          this.CurrentAnnotationAssessment.selected_dates = {
            date_picker_menu: false,
            date_range_value: "",
            date_filter: []
          };
        return;
      }
      const users = is_english_assessment
        ? this.CurrentEngAssessment.selected_candidates
        : this.CurrentAnnotationAssessment.selected_candidates;
      this.extract_assessments_with_filters(users, dates);
    },
    extract_assessments_with_filters(
      selected_users: SearchUserObject[],
      selected_dates: string[]
    ) {
      if (
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v1 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v2 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.english_v3
      ) {
        this.CurrentEngAssessment.selected_dates.date_filter = selected_dates;
        this.CurrentEngAssessment.selected_candidates = selected_users;
      } else if (
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.annotation_v1 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.annotation_v2 ||
        this.get_active_assessment.assessment_type ===
          AppAssessmentNames.annotation_v3
      ) {
        this.CurrentAnnotationAssessment.selected_dates.date_filter =
          selected_dates;
        this.CurrentAnnotationAssessment.selected_candidates = selected_users;
      }
    },
    update_score_filter(filter: string) {
      this.$emit("update_filter", filter);
    },
    update_status_filter(filter: string) {
      this.$emit("update_status_filter", filter);
    }
  }
});
